import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          {/* <b className="purple">Who We Are:</b>
            <br /><br /> */}
            We are a dynamic and forward-thinking tech startup founded by a team of industry experts who share a common vision: to transform the way businesses harness technology. 
            With a relentless commitment to excellence, we have quickly established ourselves as a trusted partner for businesses of all sizes.
            <br /><br /><br />
          </p>
          <ul>
            
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "The only way to do great work is to love what you do."{" "}
          </p>
          <footer className="blockquote-footer">Steve Jobs</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
