import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
//import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
             
              <h1 className="heading-name">
                <strong className="main-name"> SparkMind Ventures </strong>
              </h1>
              <p className="home-about-body">
                At SparkMind Ventures, we are the architects of the digital future. we are driven by innovation and a passion for solving complex technological challenges. 
                We specialize in delivering cutting-edge IT solutions that empower businesses and organizations to thrive in the digital age.
                <br /><br /><br />
                <b className="purple">Who We Are:</b>
                <br /><br />
                We are a dynamic and forward-thinking tech startup founded by a team of industry experts who share a common vision: to transform the way businesses harness technology. 
                With a relentless commitment to excellence, we have quickly established ourselves as a trusted partner for businesses of all sizes.
                <br /><br /><br />
                <b className="purple">Our Mission:</b> 
                <br /><br />
                Our mission is simple yet ambitious—to make technology accessible and beneficial for everyone. 
                We believe that technology should not be a barrier but a bridge to success. 
                We strive to democratize innovation and provide solutions that drive tangible results.
                <br /><br /><br />
                {/* <b className="purple">Join Us on the Journey:</b>
                <br /><br />
                Partner with SparkMind Ventures to embark on a transformative journey into the digital future. 
                Together, we will drive innovation, maximize efficiency, and achieve unprecedented success.
                <br />          */}
              
              </p>
            </Col>

            <Col md={5} style={{ paddingBottom: 10 }}>
              {/* <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              /> */}
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
