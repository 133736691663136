import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { MdCall,MdEmail, MdLocationPin } from "react-icons/md";

function Contact() {
  return (
    <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
             
              <h1 className="heading-name">
                <strong className="purple"> Contact Us: </strong>
              </h1>
              <p className="home-about-body">
              Ready to embrace the future? Let's start the conversation. Reach out to us to explore how our IT solutions can propel your business forward.
                <br /><br />
                 {/*<MdCall /> : 020 000000000    <br />*/}
                <MdEmail /> : <a 
                                  href="mailto:contact@sparkmindventures.com" 
                                  target="_blank" 
                                  rel="noopener noreferrer">
                                    contact@sparkmindventures.com
                              </a>  <br />
                
                <MdLocationPin /> : Pune MH India<br />
              </p>

              { <div style={{ padding: 50, textAlign: "left" }}>
                
              </div> }
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              {/* <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              /> */}
            </Col>
          </Row>
        </Container>
      </Container>
  );
}

export default Contact;
