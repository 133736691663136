import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
          <p className="home-about-body">
                
                <b className="purple">What We Offer:</b>
                <br /><br />
                
                <b>Software Development:</b> From web applications to mobile apps, we create digital experiences that captivate and engage your audience.
                <br />
                <b>Cloud Solutions:</b> Harness the power of the cloud for scalability, flexibility, and cost-efficiency.
                <br />
                <b>Cybersecurity:</b> Protect your assets with our state-of-the-art cybersecurity solutions.
                <br />
                <b>AI and Machine Learning:</b> Leverage AI to gain insights, automate tasks, and drive smarter decision-making.
                <br />
                <b>Consulting:</b> Rely on our expertise for strategic guidance and technology roadmaps.
                <br /><br /><br />
                <b className="purple">Why Choose Us?</b> 
                <br /><br />
                Innovation at the Core: We live and breathe innovation. Our commitment to staying ahead of the technological curve ensures that our clients benefit from the latest advancements.
                <br />
                Tailored Solutions: We understand that every business is unique. That's why we craft bespoke IT solutions that perfectly align with our clients' specific needs and objectives.
                <br />
                Security and Reliability: Security is paramount. We implement robust measures to safeguard your data, ensuring trust and reliability at every level.
                <br />
                Dedicated Team: Our passionate team of IT experts is dedicated to your success. We are with you every step of the way, from ideation to execution and beyond.
                <br /><br /><br />
                <b className="purple">Join Us on the Journey:</b>
                <br /><br />
                Partner with SparkMind Ventures to embark on a transformative journey into the digital future. 
                Together, we will drive innovation, maximize efficiency, and achieve unprecedented success.
                <br />         
              
              </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              {/* <img src={myImg} className="img-fluid" alt="avatar" />*/ }
            </Tilt>
          </Col>
        </Row>
        <Row>
         
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
